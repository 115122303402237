import apiAxios from '../../libs/apiAxios'

export default { 
  namespaced: true,

  // Data
  state: {
    data: []
  },

  // Get data
  getters: {
    get(state) {
      return state.data;
    }
  },

  // Change data synchronously
  mutations: {
    set(state, payload) {      
        state.data = payload;
    },

    init(state) {
      state.data = [];
    }   
  },

  // Async methods
  actions: {
    async retrieve(context, payload) {
      let queryString = '';

      // Organization id from app store
      const orgId = context.rootGetters['app/get'].orgId;
      if (orgId !== null) {
        queryString = '?orgId=' + orgId;
      }

      if (payload.exam !== undefined && payload.exam !== null && payload.exam !== 0) {
        queryString = (queryString === '' ? '?' : queryString+'&')  + 'examId=' + payload.exam;
      }           

      if (payload.doctor !== undefined && payload.doctor !== null && payload.doctor !== 0) {
        queryString = (queryString === '' ? '?' : queryString+'&')  + 'doctorId=' + payload.doctor;
      }      

      let response = await apiAxios.get('/locations'+queryString);

      context.commit('set', response.data);
    }    
  }
};